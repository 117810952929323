import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@cuttingroom/core-components';
import { AuthenticateHandlerOperation, CHECK_SSO } from '../utils/interface';
import Loader from '../../common/Loader';
import * as config from '../utils/interface';

const formatTenantLabel = (tenants: config.TenantWithSubdomain[], tenantName: string, subdomain: string): string => {
  const matchingTenants = tenants.filter(({ tenant }) => tenant === tenantName);

  if (matchingTenants.length === 1) {
    return tenantName;
  }

  const specificTenant = matchingTenants.find(({ subdomain: sd }) => sd === subdomain);

  return `${specificTenant?.tenant} in ${specificTenant?.subdomain}`;
};

type Props = {
  tenantList: config.TenantWithSubdomain[];
  loading: boolean;
  workspace: string;
  setWorkspace: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<config.LoginStepType>>,
  authenticateHandler: (
    _event: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line no-unused-vars
    operation: AuthenticateHandlerOperation
  ) => void;
};

const TenantSelection = ({
  tenantList,
  loading,
  workspace,
  setWorkspace,
  setStep,
  authenticateHandler,
}: Props) => {
  const [event, setEvent] = useState({} as React.ChangeEvent<HTMLInputElement>);

  const navigate = useNavigate();

  const handleWorkspaceSelection = (e: React.ChangeEvent<HTMLInputElement>, tenant: string, subdomain: string) => {
    setWorkspace(tenant);
    setEvent(e);
    navigate(`/login/${subdomain}/${tenant}`);
  };

  useEffect(() => {
    if (workspace) {
      authenticateHandler(event, CHECK_SSO);
    }
  }, [workspace, authenticateHandler, event]);

  return (
    <div className="tenant-selection">
      <h3>Select your CuttingRoom</h3>

      {loading ? <Loader /> : (
        <>
          <div className="tenant-list">
            {tenantList.map(({ tenant, subdomain }) => (
              <Button
                key={tenant}
                className="tenant-item primary"
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => handleWorkspaceSelection(e, tenant, subdomain)}
              >
                {formatTenantLabel(tenantList, tenant, subdomain)}
              </Button>
            ))}
          </div>
          <Button className="change-email secondary" onClick={() => setStep(config.EMAIL_WORKSPACE_INPUT)}>Back</Button>
        </>
      )}
    </div>
  );
};

export default TenantSelection;
